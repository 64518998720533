<template>
  <al-modal
      :modal-id="modalId"
      class="al-event-section-modal"
      modal-size="xl"
      centered
  >
    <template #modal-title>
      {{section.title}}
    </template>
    {{section.description}}
    <div class="al-event-section-modal__speakers">
      <al-speaker-card
          v-for="speaker in section.speakers"
          :key="`speaker-card-${speaker.id}`"
          :first-name="speaker.first_name"
          :last-name="speaker.last_name"
          :regalia="speaker.regalia"
          :photo="speaker.photo"
      />
    </div>
  </al-modal>
</template>
<script>
import AlModal from "@/components/AlModal";
import AlSpeakerCard from "@/components/AlSpeakerCard";

export default {
  name: 'AlEventSectionModal',
  components: {AlModal, AlSpeakerCard},
  props: {
    section: {type: Object, required: true},
    modalId: {type: String, required: true}
  },

}
</script>
