<template>
  <div class="al-event-sections-list">
    <slot name="event-section-items"/>
  </div>
</template>
<script>
export default {
  name: 'AlEventSectionsList'
}
</script>
