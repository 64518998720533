export default {
    computed: {
        isStreamingFromYoutube(){
            if(this.event && this.event.link_stream){
                return this.event.link_stream.includes('youtu');
            }

            return false;
        },

        isStreamingFromZoom(){
            if(this.event && this.event.link_stream){
                return this.event.link_stream.includes('zoom');
            }

            return false;
        }
    }
}
