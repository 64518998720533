<template>
  <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f_892_4573)">
      <circle cx="20.5" cy="20.5" r="16.5" fill="#DC4433" fill-opacity="0.3"/>
    </g>
    <g filter="url(#filter1_f_892_4573)">
      <circle cx="20.5" cy="20.5" r="10.5" fill="#DC4433" fill-opacity="0.5"/>
    </g>
    <circle cx="20.5" cy="20.5" r="5.5" fill="#DC4433"/>
    <defs>
      <filter id="filter0_f_892_4573" x="0" y="0" width="41" height="41" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_892_4573"/>
      </filter>
      <filter id="filter1_f_892_4573" x="9" y="9" width="23" height="23" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_892_4573"/>
      </filter>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'OnAirIcon'
}
</script>
