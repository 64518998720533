<template>
    <iframe
        width="100%"
        height="100%"
        min-height="300px"
        scrolling="no"
        border-radius="10px"
        style="border: none"
        src=""
        allow="microphone *; camera *; speaker;"
        allowfullscreen
        :data-room="room"
        :data-start="withAuth"
        :data-email="email"
        :data-name="name"
        :data-context_id="context_id"
        class="dii-screen"
    >
    </iframe>
</template>
<script>
export default {
    name: 'AlDiiWrapper',
    props: {
        email: { type: String },
        name: { type: String },
        context_id: { type: String | Number },
        room: { type: String | Number },
        withAuth: { type: Boolean, default: false },
    },
    data() {
        return {
            diiScript: null,
        };
    },
    mounted() {
        this.diiScript = document.createElement('script');
        this.diiScript.src = 'https://dii.center-game.com/dii_init.js';
        document.body.appendChild(this.diiScript);
        this.diiScript.onload = () => {
            initDII();
        };
    },
};
</script>
