<template>
    <div class="announce-container">
        <al-card no-padding class="announce-event-card">
            <div class="announce-event-image">
                <img :src="event?.cover || require('@/assets/images/default-event-image.webp')" alt="" />
            </div>
            <div class="announce-event-text">
                <div class="description">
                    <div class="title">
                        Задание: <strong>{{ event.title }}</strong>
                    </div>
                    <div class="text">
                        {{ event.description }}
                    </div>
                </div>
                <div class="controls">
                    <al-btn
                        class="announce__subscribe-button"
                        icon-right
                        @click="openSubscribeModal"
                        v-if="!event.is_subscribed"
                    >
                        Записаться
                        <template #icon>
                            <al-bonus-badge :count="event.points_subscribe" v-if="event.points_subscribe" />
                        </template>
                    </al-btn>
                    <al-btn v-else @click="unsubscribeFromEvent" variant="link" class="announce__unsubscribe-button"
                        >Отписаться</al-btn
                    >
                </div>
            </div>
        </al-card>
        <div v-if="event?.sections.length">
            <al-card class="announce-shedule-card" v-if="$mq !== 'sm' && $mq !== 'xs'">
                <div class="announce-shedule-title">План заданий</div>
                <div class="announce-shedule-container">
                    <template v-for="section in event.sections">
                        <div class="marker" :key="`marker-section-${section.id}`">
                            <svg
                                width="73"
                                height="12"
                                viewBox="0 0 73 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M0.666667 6C0.666667 3.05448 3.05448 0.666667 6 0.666667C8.94552 0.666667 11.3333 3.05448 11.3333 6C11.3333 8.94552 8.94552 11.3333 6 11.3333C3.05448 11.3333 0.666667 8.94552 0.666667 6ZM6 5L73 5V7L6 7V5Z"
                                    fill="#DC4433"
                                />
                            </svg>
                        </div>
                        <div class="title" :key="`title-section-${section.id}`" @click="showEventSectionModal(section)">
                            {{ section.title }}
                        </div>
                        <div class="time-stamp" :key="`time-section-${section.id}`">
                            {{ formatDateToHours(section.begin_at) }} - {{ formatDateToHours(section.end_at) }}
                        </div>
                    </template>
                    <div class="middle-line">
                        <div class="circle" />
                        <div class="line" />
                        <div class="circle" />
                    </div>
                </div>
            </al-card>
            <al-card class="announce-shedule-card" v-else>
                <div class="announce-shedule-title">План заданий</div>
                <al-event-sections-list>
                    <template #event-section-items>
                        <al-event-section
                            v-for="section in event.sections"
                            :key="`section-${section.id}`"
                            :time-start="formatDateToHours(section.begin_at)"
                            :time-end="formatDateToHours(section.end_at)"
                            :title="section.title"
                            :description="section.description"
                            :speakers="section.speakers"
                            :id="section.id"
                        />
                    </template>
                </al-event-sections-list>
            </al-card>
        </div>
        <al-event-section-modal :section="currentSection" :modal-id="`event-section-modal-tree-${event.id}`" />
        <al-subscribe-modal
            :eventId="event.id"
            @needToUpdateEvent="$emit('needToUpdateEvent')"
        />
    </div>
</template>
<script>
import AlCard from '@/components/AlCard';
import AlSpeakerCard from '@/components/AlSpeakerCard';
import AlEventSectionsList from '@/components/AlEventSectionsList/AlEventSectionsList';
import AlEventSection from '@/components/AlEventSectionsList/AlEventSection';
import AlEventSectionModal from '@/components/AlEventSectionModal';
import AlSubscribeModal from '@/components/AlSubscribeModal';
import AlBonusBadge from '@/components/AlBonusBadge';
import EventApi from '@/services/event';
import AlBtn from '@/components/AlBtn';
import {formatDateToHours} from "@/utils/helpers";

export default {
    name: 'Announce',
    components: {
        AlBtn,
        AlCard,
        AlEventSectionsList,
        AlEventSection,
        AlSpeakerCard,
        AlEventSectionModal,
        AlBonusBadge,
        AlSubscribeModal,
    },
    props: {
        event: { type: Object },
    },
    data: () => ({
        currentSection: {},
    }),
    methods: {
        showEventSectionModal(section) {
            this.currentSection = section;
            this.$bvModal.show(`event-section-modal-tree-${this.event?.id}`);
        },
        formatDateToHours,

        openSubscribeModal() {
            this.$bvModal.show('event-subscribe-modal');
        },

        async unsubscribeFromEvent() {
            const [err, result] = await EventApi.unsubscribeFromEventById(this.event.id);
            if (err) {
                alert('При отписке произошла ошибка, попробуйте ещё раз');
            }
            this.$emit('needToUpdateEvent');
        },
    },
};
</script>
