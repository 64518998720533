<template>
  <div class="al-event-section" @click="showEventSectionModal">
    <div class="al-event-section__dot">
    </div>
    <div class="al-event-section__time">
      {{timeStart}} - {{timeEnd}}
    </div>
    <div class="al-event-section__button">
      <div class="al-event-section__text">
        {{title}}
      </div>
    </div>
    <al-event-section-modal
    :section="$props"
    :modal-id="`event-section-modal-${id}`"
    />
  </div>
</template>
<script>
import AlModal from "@/components/AlModal";
import AlSpeakerCard from "@/components/AlSpeakerCard";
import AlEventSectionModal from "@/components/AlEventSectionModal";
export default {
  name: 'AlEventSection',
  components: {AlEventSectionModal, AlSpeakerCard, AlModal},
  props: {
    id: {type: Number | String, required: true},
    title: {type: String, required: true},
    description: {type: String, default: ''},
    timeStart: {type: String, required: false, default: '--'},
    timeEnd: {type: String, required: false, default: '--'},
    speakers: {type: Array, default: []}
  },

  methods: {
    showEventSectionModal(){
      this.$bvModal.show(`event-section-modal-${this.id}`);
    }
  }
}
</script>
