<template>
  <component
    :is="eventViewComponent"
    :event="eventItem"
    v-if="eventItem"
    @needToUpdateEvent="updateEventData"
  />
</template>
<script>
import ConstantsMixin from "@/mixins/constantsMixin";
import EventOnline from "@/views/Event/EventOnline";
import EventCompleted from "@/views/Event/EventCompleted";
import EventApi from "@/services/event";
import Announce from "@/views/Event/Announce";

export default {
  name: 'EventIndex',
  mixins: [ConstantsMixin],
  components: {EventOnline, EventCompleted, Announce},
  computed: {
    eventViewComponent(){
      if(this.eventItem) {
        switch (this.eventItem.status) {
          case this.constants.EVENT_STATUS_STARTED:
            return 'EventOnline';
          case this.constants.EVENT_STATUS_ENDED:
            return 'EventCompleted';
          case this.constants.EVENT_STATUS_NOT_STARTED:
            return 'Announce';
          default:
            return null;
        }
      }
      return null
    }
  },
  data(){
    return {
      eventItem: {
        id: null,
        title: '',
        description: '',
        status: null,
        cover: '',
        begin_at: '',
        end_at: '',
      },
    }
  },

  async mounted() {
    await this.updateEventData();
  },

  methods: {
    async updateEventData(){
      const [err, result] = await EventApi.getEventById(this.$route.params.eventId);
      if(err) {
        await this.$router.push({
          name: 'main'
        });
      }
      this.eventItem = result;
    }
  }
}
</script>
