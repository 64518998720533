<template>
    <a :href="targetLink" class="al-material-link__wrapper" :class="{
      'al-material-link__without-bonus': !bonusCount
    }" :target="target">
        <al-card class="al-material-link" no-padding>
            <div class="al-material-link__inner">
              <div class="al-material-link__icons">
                <div class="al-material-link__icon">
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        v-if="fileType === 'PDF'"
                    >
                        <path
                            d="M20 11.4287V10.0001H15.7143V17.1429H17.1429V14.2858H19.2857V12.8572H17.1429V11.4287H20ZM12.1429 17.1429H9.28571V10.0001H12.1429C12.711 10.0007 13.2557 10.2267 13.6575 10.6284C14.0592 11.0301 14.2851 11.5748 14.2857 12.143V15.0001C14.2851 15.5682 14.0592 16.1129 13.6575 16.5147C13.2557 16.9164 12.711 17.1423 12.1429 17.1429ZM10.7143 15.7144H12.1429C12.3322 15.7142 12.5138 15.6389 12.6477 15.5049C12.7816 15.371 12.857 15.1895 12.8571 15.0001V12.143C12.857 11.9536 12.7816 11.772 12.6477 11.6381C12.5138 11.5042 12.3322 11.4289 12.1429 11.4287H10.7143V15.7144ZM6.42857 10.0001H2.85714V17.1429H4.28571V15.0001H6.42857C6.80728 14.9995 7.17031 14.8488 7.4381 14.581C7.70588 14.3133 7.85658 13.9502 7.85714 13.5715V11.4287C7.85676 11.0499 7.70613 10.6868 7.43831 10.419C7.17048 10.1511 6.80734 10.0005 6.42857 10.0001ZM4.28571 13.5715V11.4287H6.42857L6.42929 13.5715H4.28571Z"
                            fill="white"
                        />
                        <path
                            d="M14.2857 8.57157V5.71446C14.2882 5.62059 14.2704 5.52728 14.2334 5.44097C14.1964 5.35465 14.1411 5.27738 14.0714 5.21447L9.07143 0.214526C9.00854 0.144773 8.93127 0.0894921 8.84495 0.0524973C8.75863 0.0155025 8.66531 -0.00232556 8.57143 0.000242883H1.42857C1.05004 0.00137339 0.687332 0.152244 0.419668 0.419906C0.152003 0.687567 0.00113052 1.05027 0 1.4288V18.5714C0 18.9503 0.15051 19.3137 0.418419 19.5816C0.686328 19.8495 1.04969 20 1.42857 20H12.8571V18.5714H1.42857V1.4288H7.14286V5.71446C7.14399 6.09299 7.29486 6.45569 7.56253 6.72335C7.83019 6.99101 8.1929 7.14188 8.57143 7.14301H12.8571V8.57157H14.2857ZM8.57143 5.71446V1.71451L12.5714 5.71446H8.57143Z"
                            fill="white"
                        />
                    </svg>
                </div>
              <al-bonus-badge :count="bonusCount" v-show="bonusCount > 0" class="al-material-link__bonus" />
            </div>
                <div class="al-material-link__text">
                    {{ linkText }}
                </div>

            </div>
        </al-card>
    </a>
</template>
<script>
import AlCard from '@/components/AlCard';
import AlBonusBadge from '@/components/AlBonusBadge';

export default {
    name: 'AlMaterialLink',
    components: { AlCard, AlBonusBadge },
    props: {
        link: { type: String, required: false, default: '/' },
        fileType: { type: String, default: 'PDF' },
        bonusCount: { type: Number, default: 0 },
        linkText: { type: String, default: 'Материал от спикера' },
        target: { type: String, default: '_blank' },
    },
    mounted() {
        console.log(this.link);
    },
    computed: {
        targetLink() {
            return `${this.link}?Authorization=Bearer ${localStorage.getItem('access_token')}`;
        },
    },
};
</script>
