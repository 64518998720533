import {EVENT_STATUS_ENDED, EVENT_STATUS_NOT_STARTED, EVENT_STATUS_STARTED} from "@/constants";

export default {
    computed: {
        constants(){
            return {
                EVENT_STATUS_NOT_STARTED,
                EVENT_STATUS_STARTED,
                EVENT_STATUS_ENDED
            }
        }
    }
}
