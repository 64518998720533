<template>
  <al-modal
      modal-id="typeform-modal"
      modal-size="xl"
      centered
      no-close
      hide-header
      no-padding
  >
    <div class="al-legend">
      <div class="al-legend__body al-legend__interactive">
        <al-typeform
            :tf-widget="tfWidget"
            tf-iframe-props="title=Альфа-Банк цифровой майндсет"
            width="100%"
            height="700px"
            :tf-hidden="tfHidden"
        />
        <div class="al-legend__footer">
          <al-btn variant="link" size="lg" class="al-legend__button" @click="$bvModal.hide('typeform-modal')">Закрыть</al-btn>
        </div>
      </div>

    </div>

  </al-modal>
</template>
<script>
import AlModal from "@/components/AlModal";
import AlTypeform from "@/components/AlTypeform";
import AlBtn from "@/components/AlBtn";

export default {
  name: 'AlTypeformModal',
  components: {AlModal, AlTypeform, AlBtn},
  props: {
      tfLink: {type: String, required: true},
      tfHidden: {type: String}
  },
  computed: {
    tfWidget(){
      return this.tfLink.split('/to/').pop();
    }
  }
}
</script>
