<template>
<div class="al-zoom-player">
  <h3 class="al-zoom-player__title">Нажмите на кнопку, чтобы присоединиться к трансляции</h3>
  <al-btn size="lg" :href="link" target="_blank" class="al-zoom-player__button">
    Запустить трансляцию
  </al-btn>
</div>
</template>
<script>
import AlBtn from "@/components/AlBtn";
export default {
  name: 'AlZoomPlayer',
  components: {AlBtn},
  props: {
    link: {type: String, required: true}
  }
}
</script>
