<template>
    <div>
        <div class="event">
            <b-container class="event__container">
                <div class="event__heading">
                    <div class="event__title">
                        <h3 class="event__title">{{ event.mission_title }}</h3>
                    </div>
                    <div class="event__status">
                        <on-air-icon class="event__status-icon" />
                        <span class="event__status-name">Прямой эфир</span>
                    </div>
                </div>

                <div class="event__grid">
                    <div class="event__section-main event__grid-item">
                        <al-card
                            style="height: 100%"
                            :class="{ 'video-container': isStreamingFromYoutube }"
                            :no-padding="$mq === 'xl'"
                            overflow-hidden
                        >
                            <youtube
                                :video-id="videoId"
                                player-width="100%"
                                player-height="100%"
                                style="height: 100%"
                                v-if="isStreamingFromYoutube"
                            />
                            <al-zoom-player v-if="isStreamingFromZoom" :link="event.link_stream" style="height: 100%" />
                        </al-card>
                    </div>

                    <div class="event__section-interactive event__grid-item">
                        <al-card style="height: 100%" no-padding overflow-hidden>
                            <al-dii-wrapper
                                v-if="event"
                                :email="getEmail"
                                :name="getFullName"
                                :context_id="event.id"
                                :with-auth="true"
                                :room="diiRoomId"
                            />
                            <!--              <iframe-->
                            <!--                  width="100%"-->
                            <!--                  height="100%"-->
                            <!--                  min-height="300px"-->
                            <!--                  scrolling="no"-->
                            <!--                  border-radius="10px"-->
                            <!--                  style="border:none"-->
                            <!--                  :src="iframeURL"-->
                            <!--                  allow="microphone *; camera *; speaker;" allowfullscreen>-->
                            <!--              </iframe>-->
                        </al-card>
                    </div>

                    <div class="event__section-description event__grid-item">
                        <al-card style="height: 100%" overflow-hidden>
                            <h3 class="event__section-title">
                                {{ event.title }}
                            </h3>
                            <b-card-text class="event__section-text">
                                {{ event.description }}
                            </b-card-text>
                        </al-card>
                    </div>
                </div>
            </b-container>
        </div>
    </div>
</template>
<script>
import AlCard from '@/components/AlCard';
import OnAirIcon from '@/components/icons/OnAirIcon';
import DeviceMixin from '@/mixins/deviceMixin';
import AlBtn from '@/components/AlBtn';
import EventApi from '@/services/event';
import ConstantsMixin from '@/mixins/constantsMixin';
import AlZoomPlayer from '@/components/AlZoomPlayer';
import detectStreamSource from '@/mixins/detectStreamSource';
import { mapGetters } from 'vuex';
import AlDiiWrapper from '@/components/AlDiiWrapper';

export default {
    name: 'EventOnline',
    mixins: [DeviceMixin, ConstantsMixin, detectStreamSource],
    components: { AlDiiWrapper, AlZoomPlayer, OnAirIcon, AlCard, AlBtn },
    props: {
        event: { type: Object },
    },

    computed: {
        ...mapGetters('auth', ['getEmail', 'getFullName']),
        videoId() {
            if (this.event.link_stream) {
                return this.$youtube.getIdFromURL(this.event.link_stream);
            }
            return null;
        },

        iframeURL() {
            const baseURL = new URL(this.event.link_dii ?? 'https://dii.center-game.com/stream_screen.php?UID=1');

            baseURL.searchParams.append('email', this.getEmail);
            baseURL.searchParams.append('context_id', this.event.id);
            baseURL.searchParams.append('name', this.getFullName);

            return baseURL.toString();
        },

        diiRoomId() {
            return this.event.link_dii.includes('https://') ? 1 : this.event.link_dii;
        },
    },
};
</script>
