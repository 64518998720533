<template>
<div class="al-speaker-card">
  <div class="al-speaker-card__photo">
    <img :src="photo" alt="">
  </div>
  <div class="al-speaker-card__name">
    {{firstName}} {{lastName}}
  </div>
  <div class="al-speaker-card__regalia">
    {{regalia}}
  </div>
</div>
</template>
<script>
export default {
  name: 'AlSpeakerCard',
  props: {
    photo: {type: String, required: false},
    firstName: {type: String, required: true},
    lastName: {type: String, required: false, default: ''},
    regalia: {type: String, required: false, default: ''}
  },

}
</script>
