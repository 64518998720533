<template>
    <div v-if="event">
        <div class="event event-completed">
            <al-typeform-modal
                v-if="event.link_typeform"
                :tf-link="event.link_typeform"
                :tf-hidden="`email=${getEmail},context_id=${event.id}`"
            />
            <b-container class="event__container">
                <div class="event__heading">
                    <div class="event__title">
                        <h3 class="event__title">{{ event.mission_title }}</h3>
                    </div>
                    <div class="event__status">
                        <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_f_901_10453)">
                                <circle cx="20.5" cy="20.5" r="16.5" fill="#5E5E5E" fill-opacity="0.3" />
                            </g>
                            <g filter="url(#filter1_f_901_10453)">
                                <circle cx="20.5" cy="20.5" r="10.5" fill="#606060" fill-opacity="0.5" />
                            </g>
                            <circle cx="20.5" cy="20.5" r="5.5" fill="#858585" />
                            <defs>
                                <filter
                                    id="filter0_f_901_10453"
                                    x="0"
                                    y="0"
                                    width="41"
                                    height="41"
                                    filterUnits="userSpaceOnUse"
                                    color-interpolation-filters="sRGB"
                                >
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                    <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_901_10453" />
                                </filter>
                                <filter
                                    id="filter1_f_901_10453"
                                    x="9"
                                    y="9"
                                    width="23"
                                    height="23"
                                    filterUnits="userSpaceOnUse"
                                    color-interpolation-filters="sRGB"
                                >
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                    <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_901_10453" />
                                </filter>
                            </defs>
                        </svg>

                        <span class="event__status-name">Запись {{ $mq !== 'xs' && $mq !== 'sm' ? 'эфира' : '' }}</span>
                    </div>

                    <div class="event-completed__test-button ml-auto" v-if="event.link_typeform">
                        <al-btn size="lg" class="text-button-2" v-b-modal.typeform-modal icon-right>
                            Пройти тест
                            <template #icon>
                                <al-bonus-badge :count="event.points_typeform ?? 0" />
                            </template>
                        </al-btn>
                    </div>
                </div>

                <div class="event__grid">
                    <div class="event__section-main event__grid-item" ref="mainSection">
                        <al-card
                            style="height: 100%"
                            class="video-container"
                            :no-padding="$mq === 'xl'"
                            overflow-hidden
                        >
                            <youtube
                                :video-id="videoId"
                                player-width="100%"
                                player-height="100%"
                                style="height: 100%"
                            />
                        </al-card>
                    </div>
                    <div class="event__section-interactive event__grid-item">
                        <al-card style="height: 100%" no-padding overflow-hidden>
                            <div class="event-completed__module">
                                <div class="event-completed__module-info">
                                    <div class="event-completed__module-img">
                                        <img
                                            :src="event?.cover || require('@/assets/images/default-event-image.webp')"
                                            alt=""
                                        />
                                    </div>
                                    <div class="event-completed__module-description">
                                        <h4 class="event-completed__module-title">
                                            {{ event.title }}
                                        </h4>
                                        <p class="event-completed__module-text">
                                            {{ event.description }}
                                        </p>
                                    </div>
                                </div>
                                <div class="event-completed__module-plan" v-if="event.sections.length">
                                    <div class="event-completed__plan-heading">
                                        <h4 class="event-completed__module-title">План задания</h4>
                                    </div>

                                    <al-event-sections-list>
                                        <template #event-section-items>
                                            <al-event-section
                                                v-for="section in event.sections"
                                                :key="`section-${section.id}`"
                                                :time-start="formatDateToHours(section.begin_at)"
                                                :time-end="formatDateToHours(section.end_at)"
                                                :title="section.title"
                                                :description="section.description"
                                                :speakers="section.speakers"
                                                :id="section.id"
                                            />
                                        </template>
                                    </al-event-sections-list>
                                </div>
                            </div>
                        </al-card>
                    </div>

                    <div class="event__section-description event__grid-item">
                        <al-card style="height: 100%" overflow-hidden>
                            <h3 class="event__section-title">Дополнительные данные</h3>
                            <div class="event-completed__materials">
                                <al-material-link
                                    v-for="material in event.materials"
                                    :link="material.link"
                                    :link-text="material.title"
                                    :bonus-count="material.points_to_receive ?? 0"
                                    class="event-completed__material-item"
                                    :key="`material-${material.id}`"
                                />
                            </div>
                        </al-card>
                    </div>
                </div>
            </b-container>
        </div>
    </div>
</template>
<script>
import AlCard from '@/components/AlCard';
import OnAirIcon from '@/components/icons/OnAirIcon';
import DeviceMixin from '@/mixins/deviceMixin';
import AlBtn from '@/components/AlBtn';
import AlTypeformModal from '@/components/AlTypeformModal';
import AlBonusBadge from '@/components/AlBonusBadge';
import AlMaterialLink from '@/components/AlMaterialLink';
import AlEventSectionsList from '@/components/AlEventSectionsList/AlEventSectionsList';
import AlEventSection from '@/components/AlEventSectionsList/AlEventSection';
import { mapGetters } from 'vuex';
import { formatDateToHours } from '@/utils/helpers';

export default {
    name: 'EventCompleted',
    mixins: [DeviceMixin],
    components: {
        AlEventSection,
        AlEventSectionsList,
        AlMaterialLink,
        AlBonusBadge,
        AlTypeformModal,
        OnAirIcon,
        AlCard,
        AlBtn,
    },
    props: {
        event: { type: Object },
    },
    computed: {
        ...mapGetters('auth', ['getEmail']),
        videoId() {
            if (this.event.link_record) {
                return this.$youtube.getIdFromURL(this.event.link_record);
            }

            return null;
        },
    },

    methods: {
        formatDateToHours,
    },
};
</script>
